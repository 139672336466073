jQuery(function($) {
  if($('.tapme-board').length > 0) {
    $('.tapme-board table tbody td:nth-child(3)').each(function() {
      if($(this).text().length > 0) {
        const number = $(this).text().replace(/ /g, '').substr(1);
        $(this).wrap(`<a href="tel:+358${number}"></a>`);
      }
    });
  }

  if($('.tapme-instagram').length > 0) {
    $('.tapme-instagram .tapme-instagram-post div').each(function() {
      const caption = $(this).html().replace(/(^|\s)(#[a-z\åäö\d-]+)/ig, "$1<span class='hashtag'>$2</span>");
      $(this).html(caption);
    });
  }

  if($('.tapme-timetable').length > 0) {
    $('.tapme-timetable .hour').hover(function() {
      const hour = $(this).text();
      $('.tapme-timetable .meeting[data-hours*="'+hour+'"]').each(function() {
        $(this).toggleClass('hover');
      });
    });
  }

  $('body.single-event .primary-menu .menu-item-195').addClass('current_page_ancestor');
});